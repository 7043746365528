import React from 'react'
import { Link } from 'gatsby'
import './pump.css'
import moment from 'moment'

//NPM
import styled from 'styled-components'

import Layout from '../components/layout'
import TicketTypesModal from '../components/ticket-types-modal'
import SEO from '../components/seo'
import Button from '../components/button'

const PumpHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const MainContainer = styled.div`
  text-align: center;
  max-width: 1000px;
  padding-bottom: 30px;

  @media (min-width: 1000px) {
    background-color: white;
    border-radius: 10px;
    /* border: 1px solid #E2E2E2; */
    /* box-shadow: 0 0 75 rgba(0,0,0,0.18); */
    box-shadow: 0px 0px 50px rgba(132, 132, 132, 0.2);

    margin: 40px 0 0 0;
  }
`
const Separator = styled.div`
  display: none;
  
  @media (max-width: 1000px) {
    display: block;
  }
`

const BackgroundBlur = styled.div`
  display: none;
  /* background-image: url('https://api.parse.buddy.com/files/a38a0076-083a-4fc2-91b5-0628fa800803/956ae7784f58dbe5cee59719ae78dfaf_photo.png'); */
  background-image: url(${props => props.image});
  height: 70vh;

  filter: blur(9px);
  -webkit-filter: blur(8px);

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  width: 100vw;
  position: absolute;
  z-index: -1;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  @media (min-width: 1000px) {
    display: block;
  }
`

const FlyerContainer = styled.div`
  @media (max-width: 999px) {
    /*spans the full width*/
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  @media (min-width: 1000px) {
    position: relative;
  }
`

const Flyer = styled.img`
  max-width: 550px;
  width: 100%;
  object-fit: cover;
  margin: 0;
  min-height: 375px;

  @media (min-width: 1000px) {
    border-top-left-radius: 10px;
  }
`
const Title = styled.h1`
	font-size: 26px;
	line-height: 32px;
	font-weight: 800;
	color: #401114;
	text-align: center;
	margin-bottom: 5px;
`;
const Date = styled.p`
	font-size: 15px;
	color: #878787;
	margin-bottom: 2px;

`;
const Location = styled.p`
	font-size: 13px;

	color: #565656;
	margin-bottom: 10px;
	font-weight: 700;
`;
const PumpInfo = styled.div`
  padding: 10px 10px;

  h1 {
    text-align: left;
    @media (max-width: 1000px) {
      text-align: center;
    }
  }

  @media (min-width: 1000px) {
    align-self: flex-end;
  }
`

const PumpInfoContainer = styled.div`
  padding: 0 10px;

  @media (min-width: 1000px) {
    text-align: left;
    background-color: #fff7f8;
    display: flex;
    border-top-right-radius: 10px;
    margin-bottom: 7px;
  }
`
const PricingContainer = styled.div`
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
`
const SectionTitle = styled.h2`
  font-size: 16px;
  margin-top: 30px;
`
const Price = styled.p`
  color: #33b031;
  font-family: 'Poppins';
  font-weight: 100;
  font-size: 36px;
`
export default ({ pageContext: { pump } }) => (
  <Layout>
    <SEO
      title={pump.name}
      image={pump.originalFlyer}
      twitterDescription={pump.details}
      description={`WhereDPump - ${pump.name} - ${moment(pump.date).add(4, 'h').format('dddd, MMMM Do YYYY, h:mm a')} at ${pump.location}. Find event and ticket information here.`}
      keywords={[
        `wheredpump`,
        `party`,
        `fete`,
        `caribbean`,
        `soca`,
        `jamaica`,
        `barbados`,
        `event`,
        `pump`,
        `digital`,
        `tickets`,
        `information`,
        `trinidad`,
        `tobago`,
        `carnival`,
        `information`,
        `${pump.name}`,
      ]}
    />
    <BackgroundBlur image={pump.flyer} />
    <MainContainer>
      <PumpHeader>
        <FlyerContainer>
          <Flyer src={pump.originalFlyer} />
        </FlyerContainer>
        <PumpInfoContainer>
          <PumpInfo>
            <Title>{pump.name}</Title>
            {/* <p className="pumpDate">{pump.date}</p> */}
            <Date>
              {moment(pump.date)
                .add(4, 'h')
                .format('ddd MMMM Do YYYY, [at] h:mm a')}
            </Date>
            <Location>@ {pump.location}</Location>

            {/*For testing we want this to always show*/}
            {pump.hasTickets ? (
              <>
                {/* <ButtonBlock onClick={()=>console.log('clicked')}><Button title="Get Tickets" primary={true} /></ButtonBlock> */}
                <TicketTypesModal pump={pump} />
              </>
            ) : null}
          </PumpInfo>
        </PumpInfoContainer>
      </PumpHeader>
      <Separator>
        <hr style={{ opacity: 0.3 }} />
      </Separator>
      <PricingContainer>
        <SectionTitle>Starting From</SectionTitle>
        <Price>{pump.price}</Price>
      </PricingContainer>
      <div className="pumpDetailsContainer">
        <PricingContainer>
          <SectionTitle style={{ margin: 0 }}>Event Details</SectionTitle>
        </PricingContainer>

        <pre>{pump.details}</pre>
      </div>

      <Button link="/events" secondary title="Back to events" />
    </MainContainer>
  </Layout>
)
