import React, { Component } from 'react';
import styled from 'styled-components'
import Select from 'react-select'

const TicketContainer = styled.div`
    display: flex;
    flex-direction: row;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding: 15px;
    box-shadow: 0 3px 8px rgba(208, 208, 208, 0.4);
    align-items: center;
    max-width: 650px;
    margin: 15px auto;
    border-left-width: 7px;
    border-left-color: #fc444e;
`
const TicketInfo = styled.div`
    flex: 1;
    margin-left: 20px;
`
const TicketTitle = styled.h1`
    font-size: 17px;
    color: #1E0A3C;
    /* font-family: 'Avenir-Book'; */
    font-weight: 500;
    line-height: 21px;
    margin: 0;
    padding: 5px 0 5px 0;
`
const TicketPrice = styled.h3`
    margin: 0;
    /* font-family: 'Avenir-Heavy'; */
    font-weight: 100;
    font-size: 16px;
    padding: 0 0 10px 0;
    color: green;

`
const DropdownContainer = styled.div`
    min-width: 70px;
`

const CustomSelect = styled.select`
    padding: 5px;
    width: 50px;
    height: 40px;
    font-size: 18px;
    border: 1px solid #d0d0d0;
    background-color: white;
    box-shadow: 0 1px 7px rgba(0,0,0,0.1);
    transition: 0.3s ease;

    &:focus {
        outline: none;
        border: 1px solid green;
    }
`

class TicketStub extends Component {


    constructor(props) {
        super(props)

        this.handleInputChange = this.handleInputChange.bind(this)
    }

    handleInputChange(e) {
        let ticketCount = e.currentTarget.value  
        this.props.handleSelect(this.props.ticket, ticketCount)

    }
    

    render() {
        const options = []
        for (let i=0; i <= this.props.ticket.limitPerUser; i++) {
            options.push(<option key={i} name={this.props.ticket.ticketType} value={i}>{i}</option>)
        }
        return (
            <TicketContainer>
                <TicketInfo>
                    <TicketTitle>{this.props.type}</TicketTitle>
                    <TicketPrice>${this.props.price}.00</TicketPrice>
                </TicketInfo>
                <DropdownContainer>
                <CustomSelect onChange={this.handleInputChange}>
                    {options}
                </CustomSelect>
                </DropdownContainer>
            </TicketContainer>
        );
    }
}


export default TicketStub;