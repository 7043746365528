import React, { Component } from 'react';
import Modal from 'react-modal'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

import { GetTicketTypes } from '../services/parse-bridge'
import TicketStub from './ticket-stub';
import Button from './button';
import Loader from 'react-loader-spinner'
import axios from 'axios'
import { Mixpanel } from './mixpanel'

//Parse functions
import { loginUser } from '../services/parse-bridge'

Modal.setAppElement('#___gatsby')

const ModalHeader = styled.div`
    display: block;
    width: 100%;
    /* background-color: '#EEEDF2'; */
    /* box-shadow: 0 1px 1px 0 rgba(30,10,60,.15); */
    margin-bottom: 2px;
    line-height: 20px;

`
const ModalTitle = styled.h2`
    color: darkgray;
    text-align: center;
    /* font-family: 'Avenir-Black'; */
    margin: 0 0 10px 0;
    font-size: 20px;

`

const ButtonBlock = styled.div`
    margin: 10px 0;
`

const CheckoutButton = styled.button`
    width: 100%;
    display: block;
    font-family: 'Poppins';
    font-weight: 500;
    color: #FFF;
    background-color: #0D8547;
    border: none;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    margin: 5px auto;
    cursor: pointer;
    max-width: 650px;
    transition: 0.5s ease;

    &:focus {
        outline: none;
    }
`

const LoginContainer = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
`

const Logo = styled.img`
    height: 60px;
    width: auto;
    margin: 20px 0;
`
const Title = styled.h1`
    color: #5D323B;
    font-size: 24px;
    margin: 10px 0;
`
const Subtitle = styled.p`
    max-width: 300px;
    margin: 15px auto;
`
const FormInput = styled.input`
    max-width: 600px;
    width: 90%;
    padding: 10px;
    border: 1px solid rgba(240, 240, 240, 0.6);
    box-shadow: none;
    margin: 0 0 10px 0;
    border-radius: 5px;
`
const LoginButton = styled.button`
    background-color: #fc444e;
    color: white;
    font-family: Avenir-Heavy;
    margin: 20px 0;
    display: block;
    margin: 0 auto;
    border: none;
    width: 90%;
    padding: 9px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(252, 68, 78, 0.41);
    max-width: 600px;
    transition: 2s ease;

    &:focus {
        outline: none;
    }

    &:hover {
        box-shadow: 0 5px 12px rgba(252, 68, 78, 0.42);
    }
`
const LoginForm = styled.form`
    display: inline-block;
`

const FormDisclaimer = styled.p`
    color: red;
    font-family:'Avenir-Oblique';
    font-size: 14px;
    margin: 10px 0;
`

const Spinner = styled.div`
    text-align: center;
    margin: 15px 0;
`

const BuyTickets = styled.button`
    display: block;
    margin: 0 auto;
    max-width: 550px;
    width: 100%;
    color: white;
    border: none;
    background-color: #40e42e;
    padding: 7px;
    border-radius: 5px;
    text-transform: uppercase;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 16px;
    margin: 20px auto;
    box-shadow: 0 2px 6px #d6d6d6;
    cursor: pointer;
    transition: 0.8s ease;

    &:hover {
        box-shadow: 0 3px 7px rgba(57, 241, 57, 0.5);
    }

    &:focus {
        outline: 1px solid transparent;
    }

`

class TicketTypesModal extends Component {
    constructor() {
        super();

        this.state = {
            modalIsOpen: false,
            loading: true,
            needsLogin: false,
            email: "",
            password: "",
            showError: false,
            subtotal: 0,
            selectedTickets: []
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.goToCheckout = this.goToCheckout.bind(this);
    }

    componentDidMount() {
        GetTicketTypes(this.props.pump.id).then(data => {
            this.setState({ tickets: data, loading: false }, () => {
                if (this.state.tickets === undefined) {
                    console.log('not logged in')
                    this.setState({ needsLogin: true })
                }
            })
        })
    }

    handleSubmit(event) {
        this.setState({ loading: true })
        event.preventDefault()

        loginUser(this.state.email, this.state.password)
            .then(response => {
                this.setState({ loading: false })
                localStorage.setItem("wdptoken", response.data.token);

                //Track tis login in mixpanel
                Mixpanel.identify(response.data.objectId);
                Mixpanel.track('Successful login for ticket purchase');
                Mixpanel.people.set({
                    $fullName: response.data.fullName,
                    $email: response.data.email,
                    $username: response.data.username
                });
                
                //Set the header and retry the ticket call
                const AUTH_TOKEN = localStorage.getItem('wdptoken');
                axios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;

                //Retry the ticket call
                GetTicketTypes(this.props.pump.id).then(data => {
                    this.setState({ tickets: data, loading: false }, () => {
                        if (this.state.tickets === undefined) {
                            console.log('not logged in')
                            this.setState({ needsLogin: true })
                        }
                    })
                })

                // window.location.reload();
                // alert("Logged in successfully! You're now able to access event tickets.")
            })
            .catch(err=>{
                console.log(err)
                this.setState({showError: true, loading: false})

            })

    }

    updateInput = e => {
        this.setState({
            [e.target.name]: e.target.value,
            showError: false
        });
    }

    ticketsToPurchase = (ticket, count) => {

        //If count is 0, they want to remove it from purchase count
        if (count == 0) {
            console.log("user wants to remove")
            //Find element the array and remove it
            let currentTickets = this.state.selectedTickets
            let filtered = currentTickets.filter((val)=> {
                return val.ticketId !== ticket.objectId
            })
            console.log(filtered)
            console.log("setting array without this ticket")
            this.setState({selectedTickets: filtered},()=>this.generateSubtotal())

            return
        }

        //Check if the tikcet was here already,this means the user is updating price
        let currentTickets = this.state.selectedTickets
        let filtered = currentTickets.filter((val)=> {
            return val.ticketId === ticket.objectId
        })
        
        //If filtered value more than 1, user is trying to update count
        if (filtered.length > 0) {
            let removedTicket = currentTickets.filter((val)=> {
                return val.ticketId !== ticket.objectId
            })

            this.setState({
                selectedTickets: [
                    ...removedTicket,
                    {
                    ticketType: ticket.ticketType,
                    ticketPurchaseCount: count,
                    ticketId: ticket.objectId,
                    ticketPrice: ticket.ticketPrice,
                    ticketSubtotal: (ticket.ticketPrice * count)
                    }]
            },()=>this.generateSubtotal())

            return
        }

        this.setState({
            selectedTickets: [
                ...this.state.selectedTickets,
                {
                ticketType: ticket.ticketType,
                ticketPurchaseCount: count,
                ticketId: ticket.objectId,
                ticketPrice: ticket.ticketPrice,
                ticketSubtotal: (ticket.ticketPrice * count)
                }]
        },()=>this.generateSubtotal())
    }

    generateSubtotal() {
        const reducer = (accumulator, currentValue) => accumulator + currentValue;
        let prices = this.state.selectedTickets.map((tic)=>{
            return tic.ticketPurchaseCount * tic.ticketPrice
        }).reduce((acc, ticSub)=>{
            return acc + ticSub
        },0)

        this.setState({subtotal: prices})
    }

    openModal() {
        Mixpanel.track(
            "Buy button clicked", {
                "event": this.props.pump.name
            }
        )
        console.log("mix panel updated")
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        //Reset the ticket values
        this.setState({ modalIsOpen: false, selectedTickets: [], subtotal: 0 });
    }

    goToCheckout() {
        Mixpanel.track('Ticket types selected', {eventName: this.props.pump.name})
        this.setState({ loading: true })
        setTimeout(()=>{
            navigate(`/checkout/?${this.props.pump.id}`, { state: { pump: this.props.pump, tickets: this.state.tickets, ticketsToPurchase: this.state.selectedTickets, subtotal: this.state.subtotal } })
        },1000)
    }

    render() {
        return (
            <>
                <Fade bottom duration={1000}>
                    <BuyTickets onClick={this.openModal}>Buy Tickets</BuyTickets>
                </Fade>
                {/* <ButtonBlock onClick={this.openModal}><Button title="Get Tickets" primary={true} /></ButtonBlock> */}
                {/* <button onClick={this.openModal}>Get Tickets</button> */}
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                >

                    <ModalHeader>
                        <ModalTitle ref={subtitle => this.subtitle = subtitle}>Select Ticket Types</ModalTitle>
                    </ModalHeader>
                    <hr />
                    {this.state.tickets !== undefined ?
                        <>
                            {this.state.tickets.map(ticket => (
                                
                                <TicketStub
                                    key={ticket.objectId}
                                    type={ticket.ticketType}
                                    price={ticket.ticketPrice/100}
                                    limitPerUser={ticket.limitPerUser}
                                    handleSelect={this.ticketsToPurchase}
                                    ticket={ticket}
                                />
                                
                            ))}
                            
                            <hr style={{ opacity: '0.3', margin: '20px 0' }} />
                            <Fade bottom>
                                <CheckoutButton style={{backgroundColor: this.state.subtotal === 0 ? 'gray' : null, cursor: this.state.subtotal === 0 ? 'not-allowed' : null}} disabled={this.state.subtotal === 0} onClick={this.goToCheckout}>Checkout ∙ Subtotal: ${(this.state.subtotal/100).toFixed(2)}</CheckoutButton>
                            </Fade>
                            {this.state.loading && <Spinner><Loader type="Oval" color="#0D8547" height="40" width="40" /></Spinner>}
                            
                        </>
                        :
                        <LoginContainer>
                            <Logo src={require('../images/logo_colour.png')} alt="WDP Logo" />
                            <Title>Login</Title>
                            <Subtitle>You need to login in order to purchase tickets</Subtitle>
                            <LoginForm onSubmit={this.handleSubmit}>
                                <FormInput name="email" value={this.state.email} type="email" placeholder="Email Address" onChange={this.updateInput} />
                                <FormInput name="password" value={this.state.password} type="password" placeholder="Password" onChange={this.updateInput} />
                                <LoginButton type="submit">Login</LoginButton>
                                {this.state.showError && <FormDisclaimer>You've entered something wrong, please try again!</FormDisclaimer>}
                                {this.state.loading && <Spinner><Loader type="Oval" color="#Fc444e" height="40" width="40" /></Spinner>}
                            </LoginForm>

                        </LoginContainer>
                    }

                </Modal>
            </>
        );
    }
}

export default TicketTypesModal;